import { defineStore } from 'pinia'

// 定义authStore
export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: localStorage.getItem('token') || '',
        userInfo: JSON.parse(localStorage.getItem('userInfo')) || null,
        refreshToken: localStorage.getItem('refreshToken') || '',
    }),
    actions: {
        // 模拟登录请求
        login(user) {
            //console.log(user);
            this.token = user.aaccessToken
            this.userInfo = user.user
            this.refreshToken = user.refreshToken
            // 持久化存储
            // 持久化到 localStorage
            localStorage.setItem('token', this.token);
            localStorage.setItem('refreshToken', this.refreshToken);
            localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
        },
        // 登出操作
        logout() {
            this.token = '';
            this.userInfo = null;

            // 清除持久化数据
            localStorage.removeItem('token');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('refreshToken');
        },
        // 检查是否已经登录
        isLoggedIn() {
            return !!this.token;
        }
    }
});