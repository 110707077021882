const base = {
    product:{
        //商品列表
        webapi_products : '/api/products',
        webapi_products_export :'/api/products/export',
        //商品分类
        webapi_categories:'/api/categories' ,
    } ,
    login:{
        webapi_login : '/api/auth/login',
        webapi_user: '/api/user'
    },
    orders:{
        //创建订单
        webapi_createOrders : "/api/orders" ,
        //管理员指定给客户创建订单
        webapi_createOrdersAdmin :"/api/orders/agent" ,
        //删除订单
        webapi_deleteOrders: "/api/orders" ,
        //订单列表
        webapi_orders : "/api/orders" ,
        //修改订单采购单价
        webapi_orders_purchase_price : "/api/orderproducts/purchase-price" ,
        //修改订单商品售价
        webapi_orders_supply_price : "/api/orderproducts/supply-price" ,
        //采购计价完成
        webapi_orders_purchased : "/api/orders/supplied",
        //管理计价完成
        webapi_orders_priced : "/api/orders/priced",
        //客户付款完成
        webapi_orders_payment : "/api/orders/payment",
        //财务结款完成
        webapi_orders_settled : "/api/orders/settled",
        //订单统计
        webapi_orders_ordersStatistics : "/api/orders/statistics" ,
        //订单产品统计
        webapi_orders_statistics :"/api/orderproducts/statistics" ,
        //导出订单
        webapi_orders_export_statistics : "/api/orderproducts/export-statistics",
        //修改订单
        webapi_orders_modifyproducts : "/api/orderproducts" ,
        //订单汇总报表金额
        webapi_orders_summarystatistics:"/api/orders/summary-statistics" ,
        //订单商品缓存
        webapi_ordersproduct_cache:"/api/orderproducts/cache" ,
        //订单缓存
        webapi_orders_cache:"/api/orders/cache",
        //导出送货单
        webapi_orders_export_delivery:"/api/orders/export-delivery",
    },
    // 客户公司
    company:{
        //公司
        webapi_company : "/api/companies" ,
        user : {
            webapi_company_user : "/api/user" ,
        }
    },
    // 财务统计
    finance:{
        webapi_finace_grossprofit:"/api/orders/group-statistics" ,
    },



}
export default base