<template>
  <div class="menu">
    <el-menu active-text-color="#ffd04b" background-color="#112f50" class="el-menu-vertical-demo"
      :default-active="route.path" text-color="#fff" :collapse="isClose" router>
      <el-menu-item><template #title> <el-icon>
            <Sell />
          </el-icon><span class="title">订单管理系统V0.1</span> </template></el-menu-item>
      <el-sub-menu index="/">
        <template #title>
          <el-icon>
            <Setting />
          </el-icon>
          <span>系统</span>
        </template>
        <el-menu-item index="/">后台首页</el-menu-item>
        <!-- <el-menu-item index="/user/add">增加用户</el-menu-item> -->
      </el-sub-menu>
      <el-sub-menu index="/orders">
        <template #title>
          <el-icon>
            <Tickets />
          </el-icon>
          <span>订单</span>
        </template>
        <!-- <el-menu-item index="/orders/orders_create">创建订单</el-menu-item> -->
        <el-menu-item index="/orders/orders_create_new">创建订单</el-menu-item>
        <el-menu-item index="/orders/orders_manager">订单管理</el-menu-item>

      </el-sub-menu>
      <el-sub-menu index="/customer">
        <template #title>
          <el-icon>
            <UserFilled />
          </el-icon>
          <span>单位</span>
        </template>
        <el-menu-item index="/customer/customer_add">增加单位</el-menu-item>
        <el-menu-item index="/customer/customer_list">单位列表</el-menu-item>
        <el-menu-item index="/customer/service_customer_add">增加服务公司</el-menu-item>
        <el-menu-item index="/customer/service_customer_list">服务公司列表</el-menu-item>
        <!-- <el-menu-item index="/customer/customer_addadmin">添加联系人</el-menu-item>
        <el-menu-item index="/user/list">联系人列表</el-menu-item> -->
      </el-sub-menu>
      <el-sub-menu index="/finance">
        <template #title>
          <el-icon>
            <TrendCharts />
          </el-icon>
          <span>统计</span>
        </template>
        <el-menu-item index="/finance/finance_grossprofit">毛利统计</el-menu-item>
        <el-menu-item index="/orders/orders_statistics">商品统计</el-menu-item>
        <el-menu-item index="/orders/orders_supply_statistics">采购统计</el-menu-item>
        <el-menu-item index="/orders/orders_purchase_statistics">销售统计</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="/goods">
        <template #title>
          <el-icon>
            <ShoppingBag />
          </el-icon>
          <span>品类</span>
        </template>
        <el-menu-item index="/goods/goods_add">增加品类</el-menu-item>
        <el-menu-item index="/goods/goods_list">品类列表</el-menu-item>
        <el-menu-item index="/goods/goods_import">导入</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="/log">
        <template #title>
          <el-icon>
            <Document />
          </el-icon>
          <span>记录</span>
        </template>
        <el-menu-item index="/log/log_prices">类价格记录</el-menu-item>
        <el-menu-item index="/log/log_orders">订单记录</el-menu-item>
        <el-menu-item index="/log/log_system">系统记录</el-menu-item>
      </el-sub-menu>
    </el-menu>
  </div>
</template>

<script>
import {useRoute} from 'vue-router'

export default{
    props:['isClose'],
    setup(){

        //使用路由
        const route = useRoute();

        return { route }
    }
}
</script>

<style lang="less" scoped>
.menu{
    height: auto;
}
.title{
    font-size: 18px;
    color:cornsilk
}
.el-menu{
    border-right: none
}
</style>